import * as React from "react"
import Layout from '../components/Layout'
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Helmet from 'react-helmet';

import "/_custom.scss"

import * as classes from "../components/Site.module.scss"

import { StaticImage } from "gatsby-plugin-image"
import ElmLogo from "../images/es_horizontal_logo.jpg"
import NextLogo from "../images/nextSailorlogoColorDark.png"
import Favicon from "../images/favicon.ico";

const About = () => {
  return (
    <Layout>
      <Helmet>
        <title>About - Kevin Blank - kevinblank.com</title>
        <link rel="icon" type="image/x-icon" href={Favicon}></link>
      </Helmet>
      <main>
        <div >
        <Container>
          <Row>
            <Col>
              <h1 className={classes.subPageTitle}>About</h1>           
            </Col>
          </Row>
        </Container>
        </div>
        <div>
        <Container>
          <Row>
            <Col style={bigSpacing}>
                <h2>Background</h2>
                <p>
                    I grew up on the southern Oregon coast in a small town, surrounded by the Pacific Ocean and thousands of acres of wilderness. Because
                    I'm not the kind of person who sits idle well, I had lots of hobbies and interests as a kid/young adult. 
                    I joined Scouts as soon as possible and stayed in until I graduated high schoool, earning the rank of Eagle Scout.
                    In middle school and a short time in high school I played sports. 
                    In high school, I sang in jazz choir, joined the speech club, acted in 7 plays, joined and ran the high school TV production, 
                    took classes on programming and art, etc.
                </p>
                <p>
                    Throughout most of high school, I worked in part-time jobs in the computer field. My first job was building and fixing PCs. My second
                    job was tech support at the local ISP along with web design and some Unix administration.
                </p>
                <h4>
                    Education / Accomplishments
                </h4>
                    <ul>
                        <li>Eagle Scount - 1995</li>
                        <li>Tulane University - Bachelor of Arts - Art Studio - 1997-2001</li>
                        <li>Commodore of Tammany Yacht Club - 2012</li>
                    </ul>
                <hr style={{margin:"60px"}} />
                <h2>Approach to Projects/Development</h2>
                <p>
                    I love working in small, focused teams. When each member of the team has a role and expertise, and when each member is contributing fully, great ideas
                    can be brought to life. I like being in a leadership role where I can leverage my years of experience on different projects and approaches to solve
                    the problem or request at hand. Seeing my teammates or employees grow and gel definitely provides a sense of accomplishment when I'm wearing my project
                    management hat.
                </p>
                <p>
                    I've used a variety of project management tools and approaches. Which I employ on a team or project depends on what our goals are. For contract work, a hybrid between
                    Agile and waterfall works well. Making stories of the project at the beginning keeps the team in scope, but setting target dates helps keep the contract on time. When
                    working on a software dev team, I prefer a more Agile approach as I feel sprints work better to keep team members focused. Tools I've used include Github, Gitlab, Jira,
                    Trello, Workfront, Asana, Basecamp and many more.
                </p>
                <p>
                    When it comes to the design of the software project, I believe in establishing a framework before real work begins. Having well-established style guides to code, module
                    file layout, etc. helps the team more quicker and helps keeps the codebase consistent. I will leverage known code and CSS frameworks such as Bootstrap, Mui, Gatsby, NextJS, CodeIgniter,
                    Laravel and others to take care of boilerplate and provide libraries to cover gaps where my team doesn't have expertise. This also helps when onboarding new team members
                    as prior knowledge and experience in these systems gets them up to speed faster.
                </p>
                <hr style={{margin:"60px"}} />
                <h2>Hobbies / Interests</h2>
                <h4>
                    Sailing
                </h4>
                <p>
                    I've been actively racing sailboats on a local and regional level since the early 2000's. I belong to two yacht clubs (Fort Walton Yacht Club and Tammany Yacht Club).
                    I have crewed on a number of different types of sailboats: Pearson Flyer, Corsair Trimarans (24 and 31), Beneteau 40.7, J/109, Frers 33.
                    I've owned and raced a Finn, Viper 640, VX One, Force 5.
                </p>
                <h4>
                    Music
                </h4>
                <p>
                    After a variety of musical interests in high school and college, music and guitar still maintain a big pressence in my life today. I own a collection of guitars,
                    most of which I've restored, modified, or built/assembled myself. The root of influences for playing come from jazz and blues, but I gravitate toward alternative rock
                    in the styles I seek out and play.
                </p>
                <h4>
                    Art/Making/Woodworking
                </h4>
                <p>
                    I love to make physical art. My focus in college was sculpture, and I continue that with the things I build today. Since my father is a woodworker, I was exposed to
                    it at an early age. I like wood as a medium because the tools aren't so specialized that I can build in a smaller space. I'm always trying to mix technology
                    into the things I create whether it's CNC in the design/cutting or electronics in final product.
                </p>
                <h4>
                    Outdoors
                </h4>
                <p>
                    I love being near the water. I try to be on the water at least once a week in some form whether it's sailing, fishing, or just time on the beach/dock. I currently
                    have an SUP, kayak, and small fishing boat.
                </p>
                <p>
                    When I was younger, I spent a lot of time hiking and camping. In Scouts, I did an 80 mile trek at the Philmont Scout Ranch and rode over 400 miles on my bike to
                    cover the entire Oregon coast.
                </p>
            </Col>
          </Row>
        </Container>
        </div>
      </main>
    </Layout>
  )
}

export default About

// styles
const bigSpacing = {
    margin: "10px 50px",
}

const contentDiv = {
    marginTop:"30px"
}

const subContentDiv = {
    marginTop:"40px"
}

const subTitle = {
    color: '#666666',
    fontWeight: 100
}

const centerCenter = {
  position: "relative",
  left:" 50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  marginLeft: "6vw"
}